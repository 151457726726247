import React, { useState, useEffect } from "react";
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Switch, IconButton, Collapse, CircularProgress } from "@mui/material";
import { Home, ModeNight, LightMode, Menu as MenuIcon } from "@mui/icons-material";


const Sidebar = ({ isOpen, toggleSidebar, onComponentSelect, toggleThemeMode, themeMode }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [activeComponent, setActiveComponent] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(true);


  const handleSelectComponent = (componentName) => {
    setActiveComponent(componentName);
  };
  
  
  return (
    <Box flex={1} p={1} sx={{ display: { xs: "none", sm: isOpen ? "block" : "none" }, minWidth: "200px" }}>
      
      <Box position="fixed" >
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => onComponentSelect("Dashboard")}>
              <ListItemIcon><Home /></ListItemIcon>
              <ListItemText primary="Панель управління" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton onClick={() => onComponentSelect("Dashboard")}>
              <ListItemIcon><Home /></ListItemIcon>
              <ListItemText primary="Діагностика" />
            </ListItemButton>
          </ListItem>
          
          
        </List>
      </Box>
      </Box>
  );
};

export default Sidebar;
