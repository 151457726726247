export const categories = {
  r_wheel: 'Передня Підвіска та Гальма Права',
  left_front_wheel: 'Передня Підвіска та Гальма Ліва',
  right_back_wheel: 'Задня Підвіска та Гальма Права',
  r_b_wheel: 'Задня Підвіска та Гальма Ліва',
  streeteng: 'Рульовий Механізм',
  engine: 'Двигун',
  driveBelts: 'Приводні Ремені',
  electrick: 'Електрика',
  technicalFluids: 'Технічні Рідини',
  akkp   : "АКПП/КПП"
};

export const parts = {
  r_wheel: [
    'Амортизатор правий', 'Опора амортизатора права', 'Пильовик відбійник амортизатора правий',
    'Важіль верхній правий', 'Кульова опора верхня права', 'Кульова опора нижня права',
    'Важіль верхній передній правий', 'Важіль верхній задній правий', 'Важіль нижній правий',
    'Важіль нижній передній правий', 'Важіль нижній задній правий', 'Сайлентблок верхнього важеля правий',
    'Сайлентблок нижнього важеля правий', 'Втулка стабілізатора права', 'Стійка стабілізатора права',
    'Шрус зовнішній правий', 'Шрус внутрішній правий', 'Чохол шруса правий', 'Втулка балки права',
    'Сальник півосі правий', 'Піввісь права', 'Підшипник маточини правий', 'Маточина права', 'Кулак поворотний правий',
    'Диск гальмівний правий', 'Гальмівні колодки праві', 'Супорт правий', 'Направляючі супортів праві',
    'Гальмовий циліндр правий', 'Шланг гальмівний правий', 'Трубка гальмівна права', 'Датчик ABS правий',
    'Головний гальмівний циліндр', 'Датчик зносу колодок правий', 'Пильовики направляючих супортів праві'
  ],
  left_front_wheel: [
    'Амортизатор лівий', 'Опора амортизатора ліва', 'Пильовик відбійник амортизатора лівий',
    'Важіль верхній лівий', 'Кульова опора верхня ліва', 'Кульова опора нижня ліва',
    'Важіль верхній передній лівий', 'Важіль верхній задній лівий', 'Важіль нижній лівий',
    'Важіль нижній передній лівий', 'Важіль нижній задній лівий', 'Сайлентблок верхнього важеля лівий',
    'Сайлентблок нижнього важеля лівий', 'Втулка стабілізатора ліва', 'Стійка стабілізатора ліва',
    'Шрус зовнішній лівий', 'Шрус внутрішній лівий', 'Чохол шруса лівий', 'Втулка балки ліва',
    'Сальник півосі лівий', 'Піввісь ліва', 'Підшипник маточини лівий', 'Маточина ліва', 'Кулак поворотний лівий',
    'Диск гальмівний лівий', 'Гальмівні колодки ліві', 'Супорт лівий', 'Направляючі супортів ліві',
    'Гальмовий циліндр лівий', 'Шланг гальмівний лівий', 'Трубка гальмівна ліва', 'Датчик ABS лівий',
    'Головний гальмівний циліндр', 'Датчик зносу колодок лівий', 'Пильовики направляючих супортів ліві'
  ],
  


    right_back_wheel: [
      'Амортизатор правий', 'Опора амортизатора права', 'Пильовик відбійник амортизатора правий',
      'Важіль верхній правий', 'Кульова опора верхня права', 'Кульова опора нижня права',
      'Важіль верхній передній правий', 'Важіль верхній задній правий', 'Важіль нижній правий',
      'Важіль нижній передній правий', 'Важіль нижній задній правий', 'Сайлентблок верхнього важеля правий',
      'Сайлентблок нижнього важеля правий', 'Втулка стабілізатора права', 'Стійка стабілізатора права',
      'Шрус зовнішній правий', 'Шрус внутрішній правий', 'Чохол шруса правий', 'Втулка балки права',
      'Сальник півосі правий', 'Піввісь права', 'Підшипник маточини правий', 'Маточина права', 'Кулак поворотний правий',
      'Диск гальмівний правий', 'Гальмівні колодки праві', 'Супорт правий', 'Направляючі супортів праві',
      'Гальмовий циліндр правий', 'Шланг гальмівний правий', 'Трубка гальмівна права', 'Датчик ABS правий',
      'Головний гальмівний циліндр', 'Датчик зносу колодок правий', 'Пильовики направляючих супортів праві'
    ],

    r_b_wheel:[
      'Амортизатор лівий', 'Опора амортизатора ліва', 'Пильовик відбійник амортизатора лівий',
      'Важіль верхній лівий', 'Кульова опора верхня ліва', 'Кульова опора нижня ліва',
      'Важіль верхній передній лівий', 'Важіль верхній задній лівий', 'Важіль нижній лівий',
      'Важіль нижній передній лівий', 'Важіль нижній задній лівий', 'Сайлентблок верхнього важеля лівий',
      'Сайлентблок нижнього важеля лівий', 'Втулка стабілізатора ліва', 'Стійка стабілізатора ліва',
      'Шрус зовнішній лівий', 'Шрус внутрішній лівий', 'Чохол шруса лівий', 'Втулка балки ліва',
      'Сальник півосі лівий', 'Піввісь ліва', 'Підшипник маточини лівий', 'Маточина ліва', 'Кулак поворотний лівий',
      'Диск гальмівний лівий', 'Гальмівні колодки ліві', 'Супорт лівий', 'Направляючі супортів ліві',
      'Гальмовий циліндр лівий', 'Шланг гальмівний лівий', 'Трубка гальмівна ліва', 'Датчик ABS лівий',
      'Головний гальмівний циліндр', 'Датчик зносу колодок лівий', 'Пильовики направляючих супортів ліві'
    ],

    streeteng: [
      'Рульова рейка', 'Наконечник рульової тяги', 'Рульова тяга', 'Сайлентблоки рульової рейки',
      'Пиластик кермової колонки', 'Гідропідсилювач руля (насос ГУР)', 'Рідина гідропідсилювача руля',
      'Демпфер кермової колонки', 'Втулка кермової колонки'
    ],


    engine: [
      'Моторні опори (подушки двигуна)', 'Прокладка головки блоку циліндрів', 'Ремінь або ланцюг ГРМ',
      'Натяжний ролик ременя ГРМ', 'Помпа (водяний насос)', 'Термостат', 'Прокладки клапанної кришки',
      'Масляний насос', 'Сальник колінвала (передній, задній)', 'Свічки запалювання', 'Катушка запалювання',
      'Впускний колектор', 'Вихлопний колектор', 'Лямбда-зонд', 'Датчик положення колінвала',
      'Датчик положення розподільного валу', 'Датчик температури охолоджуючої рідини', 'Масляний фільтр'
    ],


    driveBelts: [
  'Ремінь генератора', 'Ремінь кондиціонера', 'Ремінь гідропідсилювача керма', 'Натяжні ролики',
  'Вільний хід (обводний ролик)'
],

electrick: [
  'Акумулятор', 'Стартер', 'Генератор', 'Основні та допоміжні реле', 'Датчики (температури, тиску, положення тощо)',
  'Фари', 'Лампочки', 'Поворотники', 'Задні ліхтарі', 'Освітлення салону', 'Клаксон',
  'Система запалювання', 'Проводка', 'Блок керування двигуном (ЕБУ)', 'Датчик ABS', 'Повітряний фільтр'
],

akkp: [
  'Масло коробки передач', 'Фільтр коробки передач (для автоматичних коробок)', 'Прокладки та ущільнення',
  'Датчик швидкості', 'Датчик положення вибірника передач', 'Трос вибору передач (для механічних коробок)',
  'Набір для ремонту селектора (для автоматичних та роботизованих коробок)', 'Синхронізатори (для механічних коробок)',
  'Вилки перемикання передач', 'Вал перемикання передач', 'Підшипники коробки передач',
  'Шестерні коробки передач', 'Корпус коробки передач', 'Втулки валів', 'Гідротрансформатор (для автоматичних коробок)',
  'Муфта зчеплення (для автоматів з двомасовим маховиком)', 'Електронний блок керування коробкою передач',
  'Демпферні пластини (для автоматичних коробок)', 'Важіль перемикання передач (куліса)',
  'Масляний радіатор коробки передач (для деяких моделей з автоматичною коробкою)'
],


technicalFluids: [
  'Моторна олива', 'Охолоджуюча рідина (антифриз)', 'Гальмівна рідина', 'Рідина гідропідсилювача керма',
  'Трансмісійна олива', 'Рідина кондиціонера', 'Омивач скла'
],
    // Додайте інші деталі за потреби
  };