import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Box, Checkbox, FormGroup, FormControlLabel, Grid, Button,
  List, ListItem, ListItemButton, ListItemIcon, ListItemText, Modal, Paper, TextField, styled,
FormLabel, RadioGroup, Radio} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import gif from "./llll.gif"
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay'; // Іконка для повтору фото
import CancelIcon from '@mui/icons-material/Cancel';
import {  MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { categories, parts } from './Data';
 // Замініть на свій шлях імпорту
 import SVGComponent from './SVG'; // Переконайтеся, що вказали правильний шлях до компоненту



 const NeonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#949191', // Темний фон для контрасту неону
  color: '#0f0', // Яскраво-зелений неон
  height: '100vh',
  padding: theme.spacing(3),
  '& .MuiTypography-root': { // Неоновий ефект для тексту
    textShadow: '0 0 7px #0f0, 0 0 10px #0f0, 0 0 21px #0f0, 0 0 42px #0f0',
  },
  '& .MuiButton-root': { // Неоновий ефект для кнопок
    borderColor: '#0f0',
    color: '#0f0',
    '&:hover': {
      backgroundColor: '#121212',
      borderColor: '#0f0',
      boxShadow: '0 0 15px #0f0, 0 0 15px #0f0, inset 0 0 15px #0f0',
    },
  },
  '& .MuiPaper-root': { // Стиль для Paper компонентів
    backgroundColor: '#002',
    border: '1px solid #0f0',
    boxShadow: '0 0 15px #0f0, 0 0 15px #0f0, inset 0 0 15px #0f0',
    margin: '10px 0', // Відступи для Paper
  },
}));




const CheckboxesForSelectedCategory = ({ selectedItemInfo, addPhoto, updateSelectedParts, updatePartsInfo }) => {
  const [selectedParts, setSelectedParts] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [notes, setNotes] = useState({});
  const [statuses, setStatuses] = useState({});
  const [photoUrls, setPhotoUrls] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [currentPhoto, setCurrentPhoto] = useState('');
  const [currentPart, setCurrentPart] = useState('');
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [partsInfo, setPartsInfo] = useState({});

  // Стани та їх назви для вибору
  const partStatuses = [
    { value: 'repair', label: 'Ремонт' },
    { value: 'replace', label: 'Заміна' },
    { value: 'recommended', label: 'Рекомендовано до ремонту' }
  ];

  // Використовуйте updatePartsInfo для передачі оновленого partsInfo
useEffect(() => {
  updatePartsInfo(partsInfo);
}, [partsInfo, updatePartsInfo]);

  useEffect(() => {
    const partsForCategory = parts[selectedItemInfo] || [];
    setSelectedParts(partsForCategory);
  }, [selectedItemInfo]);



const handleCheckboxChange = (event, part) => {
    const isChecked = event.target.checked;

    // Оновлення вибраних частин і додавання категорії до partsInfo
    setCheckedItems(prevCheckedItems => ({
      ...prevCheckedItems,
      [part]: isChecked ? { checked: isChecked, category: selectedItemInfo } : undefined
    }));
    
    // Оновлення partsInfo тільки якщо частина відмічена
    if (isChecked) {
      setPartsInfo(prev => ({
        ...prev,
        [part]: {
          ...prev[part],
          category: selectedItemInfo, // Додаємо категорію при виборі частини
          // Інші поля, якщо потрібно, можуть бути оновлені тут
        }
      }));
    } else {
      setPartsInfo(prev => {
        const {[part]: removedPart, ...rest} = prev;
        return rest; // Видаляємо частину з partsInfo
      }); // Видаляємо частину з partsInfo, якщо чекбокс знятий
    }

    updateSelectedParts(part, isChecked, selectedItemInfo);
};



  const handleTakePhoto = (event, part) => {
    const file = event.target.files[0];
    if (file) {
      const photoUrl = URL.createObjectURL(file);
      setPhotoUrls(prev => ({ ...prev, [part]: photoUrl }));
      setPartsInfo(prev => ({
          ...prev,
          [part]: {
              ...prev[part],
              photo: file, // Зберігаємо файл, а не URL, щоб його можна було відправити на сервер
          }
      }));
      updatePartsInfo(partsInfo); // Оновлюємо загальний стан partsInfo
    }
};


  const handleViewPhoto = (part) => {
    setCurrentPhoto(photoUrls[part]);
    setCurrentPart(part);
    setOpenModal(true);
  };

  

  const handleStatusChange = (event, part) => {
    const newStatus = event.target.value;
    setStatuses({ ...statuses, [part]: newStatus });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleDeletePhoto = (part) => {
    const updatedPhotoUrls = { ...photoUrls };
    delete updatedPhotoUrls[part];
    setPhotoUrls(updatedPhotoUrls);
    addPhoto(part, null);
    setOpenModal(false);
  };


  // Додавання нових функцій для обробки подій
  const handleOpenNoteModal = (part) => {
    setCurrentPart(part);
    setOpenNoteModal(true);
  };

  const handleCloseNoteModal = () => {
    setOpenNoteModal(false);
  };

  const handleOpenStatusModal = (part) => {
    setCurrentPart(part);
    setOpenStatusModal(true);
  };

  const handleCloseStatusModal = () => {
    setOpenStatusModal(false);
  };


  const handleNoteChange = (event) => {
    // Оновлюємо стан notes, не partsInfo безпосередньо тут
    const newNote = event.target.value;
    setNotes(prev => ({
      ...prev,
      [currentPart]: newNote,
    }));
  };
  

  const handleStatusChangeModal = (event) => {
    const newStatus = event.target.value;
    setPartsInfo(prev => ({
      ...prev,
      [currentPart]: {
        ...prev[currentPart],
        status: newStatus,
      }
    }));
    setOpenStatusModal(false);
    updatePartsInfo(partsInfo); // Оновлюємо загальний стан partsInfo
};

  const handleSaveNote = () => {
    setPartsInfo(prev => ({
      ...prev,
      [currentPart]: {
        ...prev[currentPart],
        note: notes[currentPart],
      }
    }));
    setOpenNoteModal(false);
    updatePartsInfo(partsInfo); // Оновлюємо загальний стан partsInfo
};


  return (
    <>
      <Box sx={{ overflowY: 'auto', p: 1 }}>
        <FormGroup>
          {selectedParts.map((part, index) => (
            <Box key={index} sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginBottom: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedItems[part] || false}
                      onChange={(event) => handleCheckboxChange(event, part)}
                      sx={{ '&.Mui-checked': { color: 'white' }}}
                    />
                  }
                  label={<Typography variant="body1" sx={{ color: 'white' }}>{part}</Typography>}
                  sx={{ '& .MuiTypography-root': { color: 'white' }, '& .MuiSvgIcon-root': { color: 'white' }}}
                />
                <Box>
                  <IconButton color="primary" component="label">
                    <CameraAltIcon sx={{ color: 'white' }} />
                    <input hidden id={`file-input-${part}`} accept="image/*" type="file" onChange={(event) => handleTakePhoto(event, part)} />
                  </IconButton>

                  <IconButton color="primary" onClick={() => handleOpenNoteModal(part)} sx={{ color: 'white' }}>
                <EditIcon />
              </IconButton>
              <IconButton color="primary" onClick={() => handleOpenStatusModal(part)} sx={{ color: 'white' }}>
                <PostAddIcon />
              </IconButton>
                  {photoUrls[part] && (
                    <>
                      <IconButton color="info" onClick={() => handleViewPhoto(part)} sx={{ color: 'white' }}>
                        <VisibilityIcon />
                      </IconButton>
                    </>
                  )}
                </Box>
              </Box>
              
            </Box>
          ))}
        </FormGroup>
      </Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="photo-view-modal"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
          <img src={currentPhoto} alt="Selected part" style={{ maxWidth: '100%' }} />
          <Box sx={{ display: 'flex', gap: 4, justifyContent: 'space-around', width: '100%' }}>
            <IconButton color="primary" component="label" sx={{ transform: 'scale(1.5)' }}>
              <ReplayIcon sx={{ fontSize: '2rem' }}/>
              <input hidden accept="image/*" type="file" onChange={(event) => handleTakePhoto(event, currentPart)} />
            </IconButton>
            <IconButton color="error" onClick={() => handleDeletePhoto(currentPart)} sx={{ transform: 'scale(1.5)' }}>
              <DeleteIcon sx={{ fontSize: '2rem' }}/>
            </IconButton>
            <IconButton color="primary" onClick={handleCloseModal} sx={{ transform: 'scale(1.5)' }}>
              <CancelIcon sx={{ fontSize: '2rem' }}/>
            </IconButton>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={openNoteModal}
        onClose={handleCloseNoteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', p: 4, display: 'flex', flexDirection: 'column' }}>
          <TextField
            autoFocus
            fullWidth
            rows={4}
            minRows={4}
            id="note"
            label="Примітка"
            type="text"
            variant="outlined"
            value={notes[currentPart] || ''}
            onChange={handleNoteChange} // Змінено на handleNoteChange
          />
          <Button onClick={handleSaveNote} sx={{ mt: 2 }}>Зберегти</Button> 
        </Box>
      </Modal>

      {/* Модальне вікно для вибору стану */}
      <Modal
        open={openStatusModal}
        onClose={handleCloseStatusModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', p: 4 }}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Стан</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={statuses[currentPart] || ''}
              name="radio-buttons-group"
              onChange={handleStatusChangeModal}
            >
              {partStatuses.map((status) => (
                <FormControlLabel value={status.value} control={<Radio />} label={status.label} />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
      </Modal>
    </>
  );
};


const CategoriesAndParts = ({car,  handleClose }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [checkedItems, setCheckedItems] = useState(new Map());
  const [photos, setPhotos] = useState({}); // Новий стан для зберігання фото
  const [viewingPhoto, setViewingPhoto] = useState(null); // Стан для відображення фото
  const [selectedParts, setSelectedParts] = useState([]);

  const [selectedItemInfo, setSelectedItemInfo] = useState(null);

  const addPhoto = (part, file) => {
    setPhotos(prevPhotos => ({ ...prevPhotos, [part]: file }));
  };

  const [partsInfo, setPartsInfo] = useState({});

  const [selectedPartsInfo, setSelectedPartsInfo] = useState([]);

  const updateSelectedParts = (part, isChecked, category) => {
    setSelectedParts(prev => {
        const newParts = prev.filter(p => p.name !== part);
        if (isChecked) {
            newParts.push({ name: part, category: selectedItemInfo }); // тут ви використовуєте selectedItemInfo для категорії
        }
        return newParts;
    });
};



  
const handleSubmit = async () => {
  const formData = new FormData();
  formData.append('car_info', JSON.stringify(car));
  console.log(partsInfo)
  Object.entries(partsInfo).forEach(([partName, partData], index) => {
    if (partData.photo) {
      formData.append(`parts[${index}].photo`, partData.photo);
    }
    formData.append(`parts[${index}].info`, JSON.stringify({
      name: partName,
      category: partData.category,
      note: partData.note,
      status: partData.status
    }));
  });

  

  try {
    const response = await fetch('http://10.20.40.2:7050/succes_diag', {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Проблема з відправленням даних');
    }

    const result = await response.json();
    console.log(result);
    alert('Діагностику завершено і дані відправлено.');
  } catch (error) {
    console.error('Помилка при відправленні даних:', error);
    alert('Помилка при відправленні даних.');
  }
};


const updatePartsInfo = (newInfo) => {
  setPartsInfo(newInfo);
};



  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const handleCheckboxChange = (event, part, side) => {
    setCheckedItems(checkedItems.set(`${part}-${side}`, event.target.checked));
  };

  const [open, setOpen] = useState(false); // Стан для контролю відкриття/закриття модального вікна

  
  
  const handlePhotoChange = (event, part) => {
    const file = event.target.files[0];
    if (file) {
      const photoUrl = URL.createObjectURL(file);
      setPhotos({ ...photos, [part]: { file, photoUrl } });
    }
  };

  const handleViewPhoto = (part) => {
    setViewingPhoto(photos[part]?.photoUrl);
  };

  const handleCloseViewPhoto = () => {
    setViewingPhoto(null);
  };
  

  const handleRetakePhoto = (part) => {
    // Видалити існуюче фото зі стану
    const newPhotos = { ...photos };
    delete newPhotos[part];
    setPhotos(newPhotos);
    
    // Закрити модальне вікно перегляду
    handleCloseViewPhoto();
    
    // Спонукати користувача знову завантажити фото
    document.getElementById(`photo-upload-${part}`).click();
  };

  const handleDeletePhoto = (part) => {
    const newPhotos = { ...photos };
    delete newPhotos[part];
    setPhotos(newPhotos);
    
    // Закрити модальне вікно перегляду
    handleCloseViewPhoto();
  };

  const handleFinish = () => {
    // Логіка для завершення або збереження дії
  };

  return (
    <Box sx={{ backgroundColor: '#4B26FF', height: '100%', padding: '10px' }}>
      <Paper sx={{backgroundColor: 'white',
        borderRadius: '20px', height: '100%'
         }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <IconButton color="inherit" onClick={handleClose}>
              <CloseIcon />
          </IconButton>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1, marginRight: '13px' }}>
              <Button variant="contained" sx={{ backgroundColor: '#1100FF' }} onClick={handleSubmit}>
                  Завершити діагностику
              </Button>
          </Box>
      </Box>

      <Box
        display="grid"
        gridTemplateColumns="repeat(12, minmax(auto, 1fr))"
        gridAutoRows="minmax(5vh, 5vh)"
        gap="10px"
      >
        
        <Box
  gridColumn="span 3"
  gridRow="span 5"
  backgroundColor="rgba(0, 0, 0, 0.3)"
  borderRadius="5px"
  sx={{
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }}
>
  <Paper
    elevation={15}
    style={{
      width: '95%',
      height: '95%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center', // Вирівнювання вмісту по лівому краю
      padding: '10px', // Додавання відступів всередині Paper
      backgroundColor: '#1100FF',
      overflowY: 'auto', // Додаємо прокрутку, якщо вміст перевищує висоту Paper
    }}
  >
   
  <img src={car.photo} alt="Опис GIF" style={{borderRadius: '14px',border: '4px solid #DEDEDE',
 width: 'auto', maxHeight: '100%' }} />

    
  </Paper>
</Box>


        <Box
          gridColumn="span 5"
          gridRow="span 5"
          backgroundColor="rgba(0, 0, 0, 0.3)"
          borderRadius="5px"
          sx={{
            width: '100%',
            height: '100%',
            // border: '2px solid #617c85',
            display: 'flex', // Включаємо флексбокс
            justifyContent: 'center', // Центруємо вміст по горизонталі
            alignItems: 'center', // Центруємо вміст по вертикалі
          }}
        >
          <Paper
            elevation={15}
            style={{
              // border: '2px solid #617c85',
              width: '95%',
              height: '95%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: '#1100FF',
            }}
          >
                <img src={gif} alt="Опис GIF" style={{ maxWidth: '100%', height: '100%' }} />

            {/* Вміст Paper */}
          </Paper>
        </Box>

        <Box
          gridColumn="span 4"
          gridRow="span 5"
          backgroundColor="rgba(0, 0, 0, 0.3)"
          borderRadius="5px"
          sx={{
            width: '100%',
            height: '100%',
            // border: '2px solid #617c85',
            display: 'flex', // Включаємо флексбокс
            justifyContent: 'center', // Центруємо вміст по горизонталі
            alignItems: 'center', // Центруємо вміст по вертикалі
          }}
        >
          <Paper
            elevation={15}
            style={{
              padding: '10px',
              // border: '2px solid #617c85',
              width: '95%',
              height: '95%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: '#1100FF',
            }}
          >
             {/* Використання Typography для відображення інформації */}
              <Typography variant="h6" gutterBottom component="div" color= 'white' sx={{ width: '100%' }}>
                Номер авто: {car.plate}
              </Typography>
              <Typography variant="body1" color= 'white' gutterBottom sx={{ width: '100%' }}>
                Марка: {car.makeModel}
              </Typography>
              <Typography variant="body1" color= 'white' gutterBottom sx={{ width: '100%' }}>
                VIN: {car.vin}
              </Typography>
          </Paper>
        </Box>

        <Box
        gridColumn="span 6"
        gridRow="span 9"
        backgroundColor="rgba(0, 0, 0, 0.3)"
        borderRadius="5px"
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Paper
          elevation={15}
          style={{
            width: '95%',
            height: '95%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: '#1100FF',
          }}
        >
          <SVGComponent setSelectedItemInfo={setSelectedItemInfo} />
        </Paper>
      </Box>

      <Box
        gridColumn="span 6"
        gridRow="span 9"
        backgroundColor="rgba(0, 0, 0, 0.3)"
        borderRadius="5px"
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Paper
          elevation={15}
          style={{
            width: '95%',
            height: '95%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: '#1100FF',
            padding: '10px'
          }}
        >
          
          <Typography variant="h6" gutterBottom component="div" color='white' sx={{ width: '100%' }}>
            {categories[selectedItemInfo] || "Виберіть категорію"}
          </Typography>


          <CheckboxesForSelectedCategory 
    selectedItemInfo={selectedItemInfo} 
    addPhoto={addPhoto} 
    updateSelectedParts={updateSelectedParts} 
    updatePartsInfo={setPartsInfo} // передаємо setPartsInfo як updatePartsInfo
/>


        </Paper>
      </Box>

        

      </Box>
      </Paper>


      


      <Modal open={!!viewingPhoto} onClose={handleCloseViewPhoto}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          <img src={viewingPhoto} alt="Перегляд фото" style={{ maxWidth: '100%', maxHeight: '90vh' }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button onClick={handleCloseViewPhoto}>Закрити</Button>
            {/* Знайдіть ключ частини, що відповідає URL фото, для виклику handleRetakePhoto або handleDeletePhoto */}
            {Object.keys(photos).find(key => photos[key].photoUrl === viewingPhoto) && (
              <>
                <Button onClick={() => handleRetakePhoto(Object.keys(photos).find(key => photos[key].photoUrl === viewingPhoto))}>Повторити</Button>
                <Button color="error" onClick={() => handleDeletePhoto(Object.keys(photos).find(key => photos[key].photoUrl === viewingPhoto))}>Видалити</Button>
              </>
            )}
          </Box>
        </Box>
</Modal>
</Box>
  );
};

export default CategoriesAndParts;
