import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Snackbar, Card, CardContent, Typography, Button, CardHeader, Badge, Container, Box, Grid,
Dialog, DialogActions, DialogTitle, DialogContentText, DialogContent, Fab } from '@material-ui/core';
import { PlayCircleOutline, Stop, Refresh, Description } from '@material-ui/icons';
import './ind.css'
import { IconButton, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@mui/icons-material/Error';
import { makeStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh'; // Імпортуємо іконку оновлення


const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed', // Позиціонування відносно вікна браузера
    bottom: theme.spacing(4), // Відступ знизу
    right: theme.spacing(4),
    width: '70px',
    height: '70px' // Відступ справа
  },
  statusIndicator: {
    height: '15px',
    width: '15px',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: '5px',
  },
  running: {
    backgroundColor: 'green',
    animation: '$blinking 0.8s linear infinite',

  },
  stopped: {
    backgroundColor: 'red',
    animation: '$blinking 1s linear infinite',

  },
  starting: {
    backgroundColor: 'yellow',
    animation: '$blinking 1s linear infinite',
  },
  '@keyframes blinking': {
    '0%': { opacity: 1.0 },
    '50%': { opacity: 0.5 },
    '100%': { opacity: 1.0 },
  },

  
  control: {
    padding: theme.spacing(1),
  },
  

  card: {
    width: '100%', // Ви можете спробувати ще менше значення для ширини
    marginBottom: theme.spacing(0.5), // Зменшуємо відступ між карточками
    padding: theme.spacing(0.5), // Зменшуємо внутрішні відступи карточки
  },

  cardContent: {
    padding: theme.spacing(0.5), // Зменшуємо відступи вмісту карточки
  },
  cardHeader: {
    padding: theme.spacing(0.5), // Зменшуємо відступи заголовка карточки
  },
  typography: {
    fontSize: '0.850rem', // Зменшуємо розмір шрифту
  },

}));

const ProcessCard = ({ process }) => {
  const [logs, setLogs] = useState('');
  
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const statusClass =
    process.statename === 'RUNNING'
      ? classes.running
      : process.statename === 'STOPPED'
      ? classes.stopped
      : classes.starting;

  const handleAction = (action) => {
    axios.get(`https://api.assist.cam/control/${action}/${process.name}`)
      .then(response => {
        // Handle the response here, for example, refresh the process list
        console.log(response.data);
        setSnackbarMessage(response.data.result);
        setSnackbarOpen(true);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  };

  const fetchLogs = () => {
    axios.get(`https://api.assist.cam/logs/${process.name}`)
      .then(response => {
        setLogs(response.data);
        setOpen(true); // Відкриває модальне вікно

      })
      .catch(error => {
        console.error('There was an error!', error); 
      });
  };


  const fetchErr = () => {
    axios.get(`https://api.assist.cam/err/${process.name}`)
      .then(response => {
        setLogs(response.data);
        setOpen(true); // Відкриває модальне вікно

      })
      .catch(error => {
        console.error('There was an error!', error); 
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Card variant="outlined" className={classes.card}>
      <CardHeader
      className={classes.cardHeader}
        avatar={
          <span className={`${classes.statusIndicator} ${statusClass}`} />
        }
        title={<Typography className={classes.typography}>{process.name}</Typography>}
      />
      <CardContent className={classes.cardContent}>
        <Typography variant="body2" component="p">Description: {process.description}</Typography>
        <Box>
      <Tooltip title="Stop">
        <IconButton aria-label="stop" onClick={() => handleAction('stop')} color="secondary">
          <Stop />
        </IconButton>
      </Tooltip>

      <Tooltip title="Start">
        <IconButton aria-label="start" onClick={() => handleAction('start')} color="primary">
          <PlayCircleOutline />
        </IconButton>
      </Tooltip>

      <Tooltip title="Restart">
        <IconButton aria-label="restart" onClick={() => handleAction('restart')}>
          <Refresh />
        </IconButton>
      </Tooltip>

      
        <Tooltip title="View Logs">
          <IconButton aria-label="view logs" onClick={fetchLogs}>
            <Description />
          </IconButton>
        </Tooltip>

        <Tooltip title="View Error">
          <IconButton aria-label="view logs" onClick={fetchErr} style={{color: 'red'}}>
            <ErrorIcon />
          </IconButton>
        </Tooltip>
      
    </Box>
      </CardContent>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Logs for {process.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <pre>{logs}</pre>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </Card>


  );
};

const SupervisorDashboard = () => {
    const [groupedProcesses, setGroupedProcesses] = useState({});
    const classes = useStyles();
    const [refreshKey, setRefreshKey] = useState(0); // Ключ для примусового оновлення

  useEffect(() => {
    axios.get('https://api.assist.cam/processes/')
      .then(response => {
        setGroupedProcesses(response.data);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }, [refreshKey]);

  const handleRefresh = () => {
    setRefreshKey(prevKey => prevKey + 1); // Змінюємо ключ, щоб спровокувати перерендеринг
  };


    return (
        <Box p={3}>
          <Typography variant="h6" style={{ color: 'white' }}>Панель керування</Typography>
                
            {Object.entries(groupedProcesses).map(([serverName, processes]) => (
            <Box key={serverName} mb={3}>
              <Typography variant="h8" style={{color: 'white'}} gutterBottom>{serverName}</Typography>
              <Grid container direction='row' spacing={1}>
                {processes.map((process) => (
                  <Grid item key={process.name} xs={12} sm={6} md={3} lg={2}>
                    <ProcessCard process={process} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          ))}

      <Fab color="primary" aria-label="refresh" className={classes.fab} onClick={handleRefresh}>
        <RefreshIcon />
      </Fab>
        </Box>
      );
    };

export default SupervisorDashboard;
