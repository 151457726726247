import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Dashboard from "./components/DASH"; // Припускаємо, що у вас є Dashboard компонент
import { createTheme, ThemeProvider, Box, CssBaseline } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Додано для маршрутизації
import DiagnosticDetails from "./components/DiagnosticDetails"; // Компонент для деталей діагностики
import SupervisorDashboard from "./components/DashBoard";
function App() {
  const SIDEBAR_WIDTH = 240;
  const SIDEBAR_WIDTH_COLLAPSED = 0;
  
  const [mode, setMode] = useState("dark");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Початковий стан сайдбару як закритий
  const [activeComponent, setActiveComponent] = useState("Dashboard");

 

    
 // Створення теми
 const theme = createTheme({
  palette: {
    // Основні кольори теми можуть бути вказані тут
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body {
          background: linear-gradient(48deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 46%, rgba(0,212,255,1) 100%);
        }
      `,
    },
  },
});



  const renderComponent = () => {
    switch (activeComponent) {
      case "Dashboard":
        return <Dashboard />;
      // Додайте випадки для інших компонентів
      default:
        return <Dashboard />;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Navbar toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} />
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${isSidebarOpen ? SIDEBAR_WIDTH : SIDEBAR_WIDTH_COLLAPSED}px)` },
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }}
        >
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/diagnostics/:diagnosticId" element={<DiagnosticDetails />} />
            <Route path="/dash/board/assist/control" element={<SupervisorDashboard />} />
          </Routes>
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;
