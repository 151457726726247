import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

const SignatureField = ({ onSave }) => {
  const sigCanvas = useRef({});
  const clear = () => sigCanvas.current.clear();
  const save = () => {
    const signatureData = sigCanvas.current.toDataURL();
    onSave(signatureData); // Виклик onSave з даними підпису
  };
  return (
    <Paper elevation={3} style={{ padding: '20px', borderRadius: '10px', marginBottom: '20px' }}>
      <SignatureCanvas
        penColor="black"
        canvasProps={{
          className: 'sigCanvas',
          style: { width: '100%', height: '200px', border: '2px solid #000', borderRadius: '10px' }
        }}
        ref={sigCanvas}
      />
      <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="outlined" color="primary" onClick={clear}>Очистити</Button>
        <Button variant="contained" color="primary" onClick={save}>Зберегти</Button>
      </div>
    </Paper>
  );
}

export default SignatureField;
