import React, {useState, useEffect} from 'react';
import { Box, Typography, Paper, Grid, CircularProgress, Button, IconButton,Fab } from '@mui/material';
import { Card, CardMedia, CardContent } from '@mui/material';
import { Modal } from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import VoiceNote from './Nonemic';
import MicIcon from '@mui/icons-material/Mic';
import {Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material';


import CategoriesAndParts from './Diagnostics';
import { styled, keyframes } from "@mui/system";


import Push from 'push.js';

const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 8px #08f;
  }
  50% {
    box-shadow: 0 0 16px #08f;
  }
  100% {
    box-shadow: 0 0 8px #08f;
  }
`;

// Стилізована карточка з неоновими ефектами
const NeonCard = styled(Card)({
  background: "linear-gradient(145deg, #1e1e2f, #252541)",
  color: "#fff",
  transition: "transform 0.3s ease-in-out",
  '&:hover': {
    transform: "scale(1.05)",
    animation: `${pulseAnimation} 1.5s infinite`
  },
  boxShadow: "0 0 8px #08f",
  borderRadius: "15px",
  overflow: "hidden", // Запобігає виходу вмісту за межі карточки
});


const NeonBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  height: '80vh',
  bgcolor: "#121212", // Темний непрозорий фон
  border: '2px solid #08f', // Неоновий синій обідок
  boxShadow: `0 0 15px #08f, 0 0 25px #08f, 0 0 35px #08f`, // Неонові тіні
  p: 4,
  borderRadius: '15px', // Закруглені краї для сучасного вигляду
  overflow: 'hidden', // Запобігає виходу контенту за межі модального вікна
  '&:focus-visible': {
    outline: 'none',
  },
}));


const NeonButton = styled(Button)({
  color: '#fff',
  backgroundColor: '#6119d0', // Основний колір кнопки
  '&:hover': {
    backgroundColor: '#a74bf9', // Колір при наведенні
    boxShadow: '0 0 10px #a74bf9, 0 0 15px #a74bf9, 0 0 20px #a74bf9', // Неоновий ефект при наведенні
  },
  height: '50px', // Висота кнопки для більшої привабливості
  margin: '10px 0', // Відступи навколо кнопки
});


const dropDownAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Стилізований компонент для тексту з анімацією
const AnimatedText = styled(Typography)({
  animation: `${dropDownAnimation} 0.5s ease-out forwards`,
  opacity: 0, // Початкове значення для анімації
});


const Dashboard = () => {
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
const [selectedCar, setSelectedCar] = useState([]);
const [showDiagnostics, setShowDiagnostics] = useState(false);
const [uploading, setUploading] = useState(false);




// const sendNotification = (carPlate) => {
//   Push.create('Новий автомобіль!', {
//     body: `Зареєстровано новий автомобіль з номером: ${carPlate}`,
//     icon: './logo.png',
//     timeout: 4000, // Згасання сповіщення через 4 секунди
//     onClick: function () {
//       // Дія при натисканні на сповіщення (опціонально)
//       window.focus();
//       this.close();
//     }
//   });
// };


const handleOpenDiagnostics = () => {
  setShowDiagnostics(true);
  setOpenModal(false); // Закрийте модальне вікно з інформацією про авто перед відкриттям діагностики
};

const handleCloseDiagnostics = () => {
  setShowDiagnostics(false);
};


const fetchCars = async () => {
  const API_KEY = 'NwM8vR3YAyE';
  const URL = 'https://api.assist.cam/data/';
  setLoading(true);

  try {
    const response = await fetch(URL, {
      headers: {
        'X-Api-Key': API_KEY
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    setCars(data); // Припускаємо, що отримані дані містять масив автомобілів під ключем 'cars'
  } catch (error) {
    console.error("Failed to fetch cars:", error);
  } finally {
    setLoading(false);
  }
};


useEffect(() => {
  fetchCars();
  setLoading(false);

  // Припустимо, що cars містить список автомобілів
  if (cars.length > 0) {
    const newCar = cars[0]; // Припустимо, що перший автомобіль є новим
    // sendNotification(newCar.plate); // Відправляємо сповіщення про новий автомобіль
  }
}, [cars]);


  const handleOpenModal = (car) => {
    setSelectedCar(car);
    setOpenModal(true);
  };
  

  const handlePhotoUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
  
    const temporaryCar = {
      id: Date.now(), // Тимчасовий ідентифікатор для нового авто
      photo: URL.createObjectURL(file), // Тимчасовий URL для відображення фото
      plate: 'Завантаження...', // Плейсхолдер для номера авто
      loading: true, // Вказуємо, що інформація про авто ще завантажується
    };
  
    setCars([temporaryCar, ...cars]); // Додаємо нову тимчасову карточку на початок списку авто
  
    setUploading(true);
    // Імітуємо завантаження на сервер і отримання відповіді
    setTimeout(async () => {
      // Припустимо, що uploadCarDetails - це ваша функція для завантаження деталей на сервер
      const carDetails = await uploadCarDetails(file); // Функція, яка "завантажує" фото на сервер і отримує деталі авто
      setCars(currentCars => currentCars.map(car => 
        car.id === temporaryCar.id ? { ...car, ...carDetails, loading: false } : car
      ));
      setUploading(false);
    }, 3000); // Імітуємо затримку сервера
  };

  const uploadCarDetails = async (file) => {
    const API_KEY = 'NwM8vR3YAyE';
    const UPLOAD_URL = 'https://api.assist.cam/get_save_photo/'; // Замініть URL на відповідний URL вашого API
  
    const formData = new FormData();
    formData.append('file', file); // 'file' - це ключ, за яким сервер розпізнає завантажуваний файл. Може варіюватися в залежності від API.
  
    try {
      const response = await fetch(UPLOAD_URL, {
        method: 'POST',
        headers: {
          'X-API-Key': API_KEY, // Додаємо API ключ у заголовки запиту
          // 'Content-Type': 'multipart/form-data', // Цей заголовок автоматично додається при використанні FormData, тому його можна опустити
        },
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const carDetails = await response.json(); // Отримуємо деталі автомобіля з відповіді сервера
      return carDetails; // Повертаємо деталі автомобіля
    } catch (error) {
      console.error("Failed to upload photo:", error);
      return null; // У випадку помилки повертаємо null або викидаємо помилку
    }
  };
  

  const MicIconWithStopPropagation = ({ onClick }) => {
    const handleClick = (event) => {
      event.stopPropagation(); // Запобігаємо вспливанню події
      onClick(); // Виконуємо бажану дію (наприклад, відкриття власного модального вікна для запису примітки)
    };
  
    return (
      <IconButton size="small" onClick={handleClick}>
        <MicIcon />
      </IconButton>
    );
  };


  const handleInputChange = (e) => {
    // Оновлення стану автомобіля тут
  };

  return (
    <>
    <Box sx={{ flexGrow: 1, p: 3,  }}>
    <Box sx={{ flexGrow: 1, p: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
          Автомобілі
        </Typography>
        <IconButton color="primary" component="label" sx={{ transform: 'scale(1.5)' }}>
          <AddAPhotoIcon />
          <input hidden accept="image/*" type="file" onChange={handlePhotoUpload} />
        </IconButton>
      </Box>
        {loading && <CircularProgress />}
        <Grid container spacing={2}>
          {cars.map((car) => (
          <Grid item xs={12} sm={4} md={2} key={car.id} onClick={() => handleOpenModal(car)}>
                  <NeonCard>

          <Card >
                <CardMedia
                  component="img"
                  height="120"
                  image={car.photo}
                  alt="Фото авто"
                />
              <CardContent onClick={(e) => e.stopPropagation()}>
                  <Typography variant="h5" component="div">
                    {car.plate}
                  </Typography>
                  
                  {car.loading && <CircularProgress />}
                  {/* Використання VoiceNote без зайвого MicIcon */}
                  {/* <VoiceNote onSave={(noteText) => console.log('Збережена примітка:', noteText)} /> */}
                </CardContent>
              </Card>
              </NeonCard>

            </Grid>
          ))}
        </Grid>
      </Box>
      {/* <Box
                component="img"
                sx={{ 
                  height: 'auto', 
                  width: '100%', 
                  maxHeight: { xs: 233, md: 340 }, 
                  maxWidth: { xs: 350, md: 350 },
                  border: '2px solid #D3D3D3',
                  borderRadius: '10px',
                  marginBottom: '20px' // Додано нижній відступ для візуального розділення вмісту
                }}
                alt="Фото авто"
                src={selectedCar.photo}
              /> */}

      <Fab color="primary" aria-label="add" component="label" sx={{ position: 'fixed', bottom: 16, right: 16 }}>
        <AddAPhotoIcon />
        <input hidden accept="image/*" type="file" onChange={handlePhotoUpload} />
      </Fab>
      <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.3)' } }}
      aria-labelledby="car-card-title"
      aria-describedby="car-card-description"
    >
    <Box
            sx={{
              overflowY: 'auto',
              p: 4,
              bgcolor: 'background.paper',
              borderRadius: 2,
              width: '70vw',
              height: '80vh',
              margin: 'auto',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          > 
             <Typography id="car-card-title" variant="h6" component="h2" gutterBottom>
          Інформація про автомобіль
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TableContainer component={Paper}>
              <Box
                component="img"
                sx={{ 
                  height: 'auto', 
                  width: '80%', 
                  maxHeight: { xs: 233, md: 340 }, 
                  maxWidth: { xs: 350, md: 350 },
                  border: '2px solid #D3D3D3',
                  borderRadius: '10px',
                  marginBottom: '20px' // Додано нижній відступ для візуального розділення вмісту
                }}
                alt="Фото авто"
                src={selectedCar.photo}
              />
              <Table aria-label="Технічна інформація">
                <TableBody>
                  <TableRow>
                    <TableCell>Рік</TableCell>
                    <TableCell>{selectedCar.year}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Марка і модель</TableCell>
                    <TableCell>{selectedCar.makeModel}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Двигун</TableCell>
                    <TableCell>{selectedCar.engine}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <TableContainer component={Paper}>
              <Table aria-label="Інформація про власника">
                <TableBody>
                  <TableRow>
                    <TableCell>VIN</TableCell>
                    <TableCell>{selectedCar.vin}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Номер</TableCell>
                    <TableCell>{selectedCar.plate}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Пробіг</TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        value={selectedCar.mileage}
                        onChange={handleInputChange}
                        variant="outlined"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Колір</TableCell>
                    <TableCell>{selectedCar.color}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Статус</TableCell>
                    <TableCell>{selectedCar.status}</TableCell>
                  </TableRow>
                </TableBody>
               
              </Table>
              
            </TableContainer>
            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Button variant="contained" color="primary" sx={{ height: 48, fontSize: '1rem' }} onClick={handleOpenDiagnostics}>
                  Провести діагностику
              </Button>
              <Button variant="contained" color="secondary" sx={{ height: 48, fontSize: '1rem' }}>
                  Огляд після ремонту
              </Button>
              <Button variant="contained" color="success" sx={{ height: 48, fontSize: '1rem' }}>
                  Завершити
              </Button>
          </Box>
          </Grid>
        </Grid>
        <Box sx={{ mt: 2, bgcolor: 'grey.100', p: 2, borderRadius: 1 }}>
          <Typography variant="h6" gutterBottom>
            Клієнт
          </Typography>
          <TextField
            label="Ім'я"
            size="small"
            value={selectedCar.clientName}
            onChange={handleInputChange}
            variant="outlined"
            sx={{ mr: 2 }}
          />
          <TextField
            label="Прізвище"
            size="small"
            value={selectedCar.clientSurname}
            onChange={handleInputChange}
            variant="outlined"
            sx={{ mr: 2 }}
          />
          <TextField
            label="Номер телефону"
            size="small"
            value={selectedCar.clientPhone}
            onChange={handleInputChange}
            variant="outlined"
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button onClick={() => setOpenModal(false)}>Закрити</Button>
        </Box>
      </Box>
    </Modal>
      

      {showDiagnostics && (
  <Modal
    open={showDiagnostics}
    onClose={() => setShowDiagnostics(false)}
    BackdropProps={{ style: { backgroundColor: 'transparent' } }} // Встановлюємо прозорий фон для Backdrop, якщо потрібно
    aria-labelledby="diagnostics-modal-title"
    aria-describedby="diagnostics-modal-description"
    sx={{ overflow: 'auto' }} // Забезпечує можливість прокрутки всередині модального вікна, якщо це необхідно
  >
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        overflowY: 'auto', // Додаємо прокрутку, якщо вміст перевищує висоту модального вікна
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'background.paper', // Ви можете змінити колір фону за бажанням
      }}
    >
      <CategoriesAndParts car={selectedCar} handleClose={handleCloseDiagnostics}/>
    </Box>
  </Modal>
)}




    </>
  );

          }

export default Dashboard;