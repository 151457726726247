import React, { useState } from "react"

import {
  AppBar,
  Toolbar,
  styled,
  Typography,
  Box,
  InputBase,
  Badge,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  menuItemClasses,
  Button
} from "@mui/material"

import MenuIcon from '@mui/icons-material/Menu';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
})



const Navbar = ({ toggleSidebar }) => {
  const [open, setOpen] = useState(false);

  const openMenu = () => setOpen(true);
  const closeMenu = () => setOpen(false);
  const [openCamera, setOpenCamera] = useState(false);

  const handleTakePhoto = () => {
    document.getElementById("take-photo-input").click();
  };

  // Обробник події зміни для input, може використовуватися для завантаження фото на сервер або інші дії
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Тут можна виконати дії з файлом, наприклад, завантажити його на сервер або відобразити на сторінці
      console.log("Зроблено фото:", file);
    }
  };

  return (
    <AppBar position="sticky">
      <StyledToolbar>
            <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mr: 2, }} // ця іконка відображається тільки на екранах менше ніж sm
        onClick={toggleSidebar}
      >
        <MenuIcon />
      </IconButton>

      <input
          accept="image/*"
          capture="environment" // цей атрибут спонукає використання камери, а не вибір файлу
          id="take-photo-input"
          type="file"
          hidden
          onChange={handleFileChange}
        />
        
      </StyledToolbar>
    </AppBar>
  );
};

export default Navbar
