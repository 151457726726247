import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Paper, CircularProgress, Container, Table, TableBody, TableCell,
     TableContainer, TableHead, TableRow,
     useMediaQuery, useTheme, Avatar, IconButton, TextField } from '@mui/material';
import axios from 'axios';
import FlipToFrontIcon from '@mui/icons-material/FlipToFront';
import FlipToBackIcon from '@mui/icons-material/FlipToBack';
import { categories, parts } from './Data';

import SignatureField from './Signature';


function DiagnosticDetails() {
    const { diagnosticId } = useParams();
    const [diagnosticData, setDiagnosticData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isFlipped, setIsFlipped] = useState(false); // Стан для відстеження сторони Paper

    const handleFlip = () => {
        setIsFlipped(!isFlipped);
    };

    useEffect(() => {
        const fetchDiagnosticData = async () => {
            try {
                const response = await axios.get(`https://api.assist.cam/diagnostics/${diagnosticId}`);
                setDiagnosticData(response.data);
                console.log(response.data)
            } catch (err) {
                setError(err.toString());
            } finally {
                setIsLoading(false);
            }
        };

        fetchDiagnosticData();
    }, [diagnosticId]);

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Typography variant="h6" color="error" textAlign="center">
                Помилка: {error}
            </Typography>
        );
    }

   
    const rows = [
        { name: 'Заміна масла', price: 500 },
        { name: 'Заміна гальмівних колодок', price: 1200 },
        { name: 'Діагностика ходової', price: 800 },
        { name: 'Діагностика ходової', price: 800 },
        { name: 'Діагностика ходової', price: 800 },
        { name: 'Діагностика ходової', price: 800 },
        { name: 'Діагностика ходової', price: 800 },
        { name: 'Діагностика ходової', price: 800 },
        { name: 'Діагностика ходової', price: 800 },
        { name: 'Діагностика ходової', price: 800 },
        { name: 'Діагностика ходової', price: 800 },
        { name: 'Діагностика ходової', price: 800 },
        { name: 'Діагностика ходової', price: 800 },
      ];
      
      const calculateTotal = rows.reduce((sum, row) => sum + row.price, 0);
      
      const CommercialOffer = () => {
        const [email, setEmail] = useState(''); // Стан для електронної пошти
        const handleEmailChange = (event) => {
            setEmail(event.target.value);
          };
        const handleSaveSignature = async (signatureData) => {
          const offerData = {
            email: email, // Додавання електронної пошти до даних для відправки

            items: rows,
            total: calculateTotal,
            signature: signatureData,
          };
      
          // Відправка даних на сервер
          try {
            const response = await fetch('http://10.20.40.2:7050/accept_kp', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(offerData),
            });
      
            if (!response.ok) throw new Error('Network response was not ok.');
            // Тут можна обробити відповідь від сервера, наприклад, показати повідомлення про успіх або завантажити PDF
      
          } catch (error) {
            console.error('Error:', error);
          }
        };
      
        return (
          <Box sx={{ margin: '20px' }}>
             <Typography variant="h6" gutterBottom>Комерційна пропозиція</Typography>
             <TableContainer component={Paper} sx={{ maxHeight: '400px', overflow: 'auto' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Назва роботи</TableCell>
                            <TableCell align="right">Ціна, грн</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">{row.name}</TableCell>
                                <TableCell align="right">{row.price}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

          <Typography variant="h6" gutterBottom sx={{ marginTop: 2, textAlign: 'right' }}>
            Загальна сума: {calculateTotal} грн
          </Typography>
          
            <div style={{ width: '100%', position: 'fixed', bottom: 0, left: 0, padding: '20px', backgroundColor: '#fff', borderTop: '2px solid #ccc' }}>
              <Typography variant="h7" gutterBottom>Ваш підпис — ваша згода на ремонт автомобіля.</Typography>
              <TextField
                label="Електронна пошта"
                type="email"
                fullWidth
                variant="outlined"
                value={email}
                onChange={handleEmailChange}
                sx={{ mb: 2 }}
            />
              <SignatureField onSave={handleSaveSignature} />
            </div>
          </Box>
        );
      };


    // Стани та їх назви для вибору
  const partStatuses = [
    { value: 'repair', label: 'Ремонт' },
    { value: 'replace', label: 'Заміна' },
    { value: 'recommended', label: 'Рекомендовано до ремонту' }
  ];

    return (
        <Container maxWidth={false} disableGutters>
            <Paper elevation={7} sx={{ position: 'relative', p: 3, minHeight: '100vh', transformStyle: 'preserve-3d', transition: 'transform 0.6s' }}>
                <IconButton onClick={handleFlip} sx={{ position: 'absolute', top: 16, right: 16, zIndex: 2 }}>
                    {isFlipped ? <FlipToFrontIcon /> : <FlipToBackIcon />}
                </IconButton>
                <Box sx={{ transform: isFlipped ? 'rotateY(180deg)' : 'rotateY(0deg)', backfaceVisibility: 'hidden' }}>

                {diagnosticData ? (
                    <>
                    
                        <Typography variant="h6" gutterBottom>
                            Деталі Діагностики
                        </Typography>
                        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                            {diagnosticData.car_info.photo && (
                                <Avatar src={diagnosticData.car_info.photo} sx={{ width: isMobile ? 64 : 128, height: isMobile ? 64 : 128, mr: 2 }} />
                            )}
                            <Box>
                                <Typography variant="h8">Інформація про автомобіль:</Typography>
                                <Typography variant="body2">Номер: {diagnosticData.car_info.plate}</Typography>
                                <Typography variant="body2">Марка та модель: {diagnosticData.car_info.makeModel}</Typography>
                                <Typography variant="body2">VIN: {diagnosticData.car_info.vin || 'Недоступно'}</Typography>
                                <Typography variant="body2">Двигун: {diagnosticData.car_info.engine}</Typography>
                            </Box>
                        </Box>

                        <Box sx={{ mb: 2 }}>
                            <Typography variant="h5" gutterBottom>Діагностовані несправності</Typography>
                            {Object.keys(diagnosticData.parts_info).map((categoryName) => (
                            <TableContainer key={categoryName}>
                                <Typography variant="h6">{categories[categoryName]}</Typography>
                                <Table>
                                    <TableHead>
                                    <TableRow>
                                        <TableCell style={{ width: '33%' }}>Назва</TableCell>
                                        <TableCell style={{ width: '33%' }}>Примітка</TableCell>
                                        <TableCell style={{ width: '34%' }}>Статус</TableCell>
                                    </TableRow>
                                    </TableHead>
                                        <TableBody>
                                        {diagnosticData.parts_info[categoryName].map((part, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{part.name}</TableCell>
                                                    <TableCell>{part.note}</TableCell>
                                                    <TableCell>
                                                        {
                                                        // Шукаємо відповідний лейбл для статусу
                                                        partStatuses.find(status => status.value === part.status)?.label ||  ''
                                                        }
                                                    </TableCell>
                                                                                                
                                            </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ))}
                        </Box>
                    </>
                ) : (
                    <Typography variant="h6">
                        Дані про діагностику не знайдено.
                    </Typography>
                )}

                </Box>

                <Box sx={{ transform: isFlipped ? 'rotateY(0deg)' : 'rotateY(-180deg)', backfaceVisibility: 'hidden', position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                    <CommercialOffer />
                </Box>
            </Paper>
        </Container>
    );
}

export default DiagnosticDetails;
